import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import clsx from 'clsx';

import logoOnly from '~/assets/logo_only.png';

type Props = {
    fixed: boolean;
    show: boolean;
};

const PromoteAppButton = (props: Props) => {
    const { show, fixed } = props;
    const [isIos, setIsIos] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isShow, setIsShow] = useState(true);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
            setIsMobile(true);
            if (/android/i.test(userAgent)) {
                setIsIos(false);
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setIsIos(true);
            }
        } else {
            setIsMobile(false);
        }
    }, []);

    useEffect(() => {
        if (isShow !== show) setIsShow(show);
    }, [show]);

    return isMobile && isShow ? (
        <div
            style={{ zIndex: 2147483003 }}
            className={clsx(
                'z-50 flex h-24 w-full flex-row items-center justify-between border-t bg-white p-2 shadow-md',
                { 'fixed bottom-0': fixed }
            )}
        >
            <CloseOutlined
                onClick={() => setIsShow(false)}
                style={{
                    fontSize: '16px',
                    color: 'grey',
                }}
                className="mr-1"
            />
            <div className="mr-4 flex items-center justify-start">
                <img width="50" height="50" src={logoOnly} alt="realty-logo" />
                <div className="ml-1">
                    <div className="text-left text-xs font-semibold text-black">
                        Your neighbours are using Realty app
                    </div>
                    <div className="text-left text-xs font-normal text-black">Download the app to stay connected</div>
                </div>
            </div>
            <Button
                type="default"
                // size="small"
                style={{
                    border: '1px solid #3b82f6',
                    color: '#3b82f6',
                }}
            >
                <a
                    href={
                        isIos
                            ? 'https://apps.apple.com/us/app/realty-com-au/id1529380861#?platform=iphone'
                            : 'https://play.google.com/store/apps/details?id=com.realtymediagroup.realtymobile'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {isIos ? 'Get' : 'Install'}
                </a>
            </Button>
        </div>
    ) : null;
};

export default PromoteAppButton;
