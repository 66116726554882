import {
    faFacebookF,
    faInstagram,
    faTwitter,
    faLinkedin,
    faPinterest,
    faTiktok,
} from '@fortawesome/free-brands-svg-icons';

import facebookIcon from '~/assets/social/custom-icons/facebook.png';
import instagramIcon from '~/assets/social/custom-icons/instagram.png';
import xIcon from '~/assets/social/custom-icons/x.png';
import linkedinIcon from '~/assets/social/custom-icons/linkedin.png';
import pinterestIcon from '~/assets/social/custom-icons/pinterest.png';
import tiktokIcon from '~/assets/social/custom-icons/tiktok.png';

export const customSocials = [
    {
        link: '//www.facebook.com/realtycomau/',
        title: 'Realty Facebook',
        icon: facebookIcon,
    },

    {
        link: '//www.instagram.com/realty.com.au/',
        title: 'Realty Instagram',
        icon: instagramIcon,
    },
    {
        link: '//twitter.com/realtyau9142',
        title: 'Realty Twitter',
        icon: xIcon,
    },
    {
        link: '//www.linkedin.com/company/realtycomau',
        title: 'Realty LinkedIn',
        icon: linkedinIcon,
    },
    {
        link: '//www.pinterest.com.au/realtycomau/',
        title: 'Realty Pinterest',
        icon: pinterestIcon,
    },
    {
        link: '//www.tiktok.com/@realtyaustralia',
        title: 'Realty Tiktok',
        icon: tiktokIcon,
    },
];

const socials = [
    {
        link: '//www.facebook.com/realtycomau/',
        title: 'Realty Facebook',
        icon: faFacebookF,
    },

    {
        link: '//www.instagram.com/realty.com.au/',
        title: 'Realty Instagram',
        icon: faInstagram,
    },
    {
        link: '//twitter.com/realtyau9142',
        title: 'Realty Twitter',
        icon: faTwitter,
    },
    {
        link: '//www.linkedin.com/company/realtycomau',
        title: 'Realty LinkedIn',
        icon: faLinkedin,
    },
    {
        link: '//www.pinterest.com.au/realtycomau/',
        title: 'Realty Pinterest',
        icon: faPinterest,
    },
    {
        link: '//www.tiktok.com/@realtyaustralia',
        title: 'Realty Tiktok',
        icon: faTiktok,
    },
];

export default socials;
