import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingDots.module.scss';

const LoadingDots = (props) => {
    const { className } = props;
    return (
        <div className={className}>
            <div className={`${styles.dot} ${styles.dot1}`} />
            <div className={`${styles.dot} ${styles.dot2}`} />
            <div className={`${styles.dot} ${styles.dot3}`} />
        </div>
    );
};

LoadingDots.propTypes = { className: PropTypes.string };

LoadingDots.defaultProps = { className: 'w-full text-center' };

export default LoadingDots;
