import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions';

class IsAuthenticated extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
        onLogoutUser: PropTypes.func,
        type: PropTypes.oneOf(['CONSUMER', 'ADMIN', 'AGENT', 'AGENCY', 'USER', 'BROKER']),
    };

    static defaultProps = {
        isAuthenticated: false,
        onLogoutUser: () => {},
        type: 'CONSUMER',
    };

    render() {
        const { onLogoutUser, isAuthenticated, children, type, ...rest } = this.props;
        return children({
            isAuthenticated,
            logoutUser: onLogoutUser,
            originalProps: rest,
            type,
        });
    }
}

const IsAuthenticatedWithState = compose(
    connect(
        state => ({
            isAuthenticated: state.auth.isAuthenticated,
            type: state.auth.type,
        }),
        dispatch => ({
            onLogoutUser() {
                dispatch(logoutUser());
            },
        }),
        null,
        { pure: false }
    )
)(IsAuthenticated);

export default IsAuthenticatedWithState;
