import { gql } from '@apollo/client';
import { Component } from 'react';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { addRecordToCache } from '~/utils/graphql';
import { TopicQuery } from '~/queries/TopicQuery.graphql';
import {
    OnTopicMessageAddedSubscriptionByConsumerId,
    TopicsConnectionQuery,
} from '~/queries/TopicsConnectionQuery.graphql';

const CONSUMER_QUERY = gql`
    {
        viewer {
            ... on Consumer {
                id
                unreadMessageCount
            }
        }
    }
`;

class ConsumerSubscription extends Component {
    componentDidMount() {
        const { isAuthenticated } = this.props;
        if (isAuthenticated) {
            this.subscribeToNewTopicMessage();
        }
    }

    subscribeToNewTopicMessage = () => {
        const {
            data: { subscribeToMore, viewer },
            client,
        } = this.props;

        if (!viewer) {
            return null;
        }
        const consumerId = viewer.id;

        return subscribeToMore({
            document: OnTopicMessageAddedSubscriptionByConsumerId,
            variables: { consumerId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newTopicMessage = subscriptionData.data.topicMessageAddedByConsumerId;

                if (newTopicMessage.sender.id === consumerId) {
                    return prev;
                }

                try {
                    addRecordToCache(
                        client,
                        TopicQuery,
                        {
                            uniqueId: newTopicMessage.topicUniqueId,
                            filter: {
                                last: 50,
                                uniqueId: newTopicMessage.topicUniqueId,
                            },
                        },
                        data => ({
                            ...data,
                            topic: {
                                ...data.topic,
                                topicMessagesConnection: {
                                    ...data.topic.topicMessagesConnection,
                                    nodes: [...data.topic.topicMessagesConnection.nodes, newTopicMessage],
                                },
                            },
                        })
                    );
                } catch (e) {
                    console.log(e);
                }
                try {
                    const variables = {
                        uniqueId: newTopicMessage.topicUniqueId,
                        filter: {
                            last: 50,
                            uniqueId: newTopicMessage.topicUniqueId,
                        },
                    };
                    const data = client.readQuery({
                        query: TopicQuery,
                        variables,
                    });

                    client.writeQuery({
                        query: TopicQuery,
                        data: {
                            ...data,
                            topic: {
                                ...data.topic,
                                latestMessage: newTopicMessage,
                            },
                        },
                        variables,
                    });
                } catch (e) {
                    console.log(e);
                }

                try {
                    const variables = {
                        filter: {
                            first: 10,
                            keyword: null,
                        },
                        membersConnectionFilter: { first: 10 },
                    };
                    const topicData = client.readQuery({
                        query: TopicsConnectionQuery,
                        variables,
                    });
                    const topic = topicData.topicsConnection.nodes.find(
                        ({ uniqueId }) => uniqueId === newTopicMessage.topicUniqueId
                    );

                    if (topic) {
                        client.writeQuery({
                            query: TopicsConnectionQuery,
                            data: {
                                ...topicData,
                                latestMessage: newTopicMessage,
                            },
                            variables,
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
                try {
                    this.props.data.refetch();
                } catch (e) {
                    console.log(e);
                }
                return prev;
            },
        });
    };

    render() {
        const { children } = this.props;
        return children;
    }
}

export default compose(
    withApollo,
    connect(state => ({
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
    })),
    graphql(CONSUMER_QUERY, { skip: ({ isAuthenticated }) => !isAuthenticated })
)(ConsumerSubscription);
