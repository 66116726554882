/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Layout } from 'antd';
import clsx from 'clsx';
import ConsumerSubscription from '~/components/ConsumerSubscription';
import PublicFooter from '~/components/PublicFooter';
import PublicHeader from '~/components/PublicHeader';
import styles from './PublicLayout.module.scss';

const PublicLayout = ({ children, routes, fixedHeader, showFooter, fullPage }) => {
    const router = useRouter();
    const currentRoute = routes.find((route) => route.path.split('/:')[0] === `/${router.pathname.split('/')[1]}`);
    const showPartnerIcons = ['/'].includes(router.pathname);

    if (currentRoute) document.title = `Realty | ${currentRoute.name}`;

    return (
        <ConsumerSubscription>
            <div className={styles.publicLayout}>
                <Layout className="default-theme layout" tagName="div">
                    <PublicHeader fixedHeader={fixedHeader} />
                    <Layout.Content
                        style={fixedHeader ? { marginTop: 105 } : {}}
                        className={clsx({ 'full-page flex flex-col': fullPage }, 'relative')}
                    >
                        {children}
                    </Layout.Content>
                    {showFooter && (
                        <Layout.Footer style={{ padding: 0 }}>
                            <PublicFooter showPartnerIcons={showPartnerIcons} />
                        </Layout.Footer>
                    )}
                </Layout>
            </div>
        </ConsumerSubscription>
    );
};

PublicLayout.propTypes = {
    children: PropTypes.any,
    router: PropTypes.shape({ pathname: PropTypes.string }),
    routes: PropTypes.arrayOf(PropTypes.object),
    fixedHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    fullPage: PropTypes.bool,
};

PublicLayout.defaultProps = {
    fixedHeader: false,
    showFooter: true,
    fullPage: true,
    router: { pathname: '' },
    routes: [],
};

export default PublicLayout;
