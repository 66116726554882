export const findIndexByPropertyId = (cacheRecordList, propertyId) => {
    let removeProperty;
    cacheRecordList.map(({ node }, index) => {
        const { id } = node;
        if (id === propertyId) {
            removeProperty = index;
        }
    });
    return removeProperty;
};

export const findIndexById = (cacheRecordList, id) => cacheRecordList.findIndex(record => record.id === id);

export const removeRecordFromCache = (store, query, variables, getRecordList, findIndexMethod, transformData) => {
    if (!findIndexMethod) throw new Error('Must implement findIndexMethod');
    if (!getRecordList) throw new Error('Must implement getRecordList');
    if (!transformData) throw new Error('Must implement transformData');

    const data = store.readQuery({
        query,
        variables,
    });

    const recordList = getRecordList(data);

    const recordIndex = findIndexMethod(recordList);

    if (recordIndex > -1) {
        const newRecords = [...recordList.slice(0, recordIndex), ...recordList.slice(recordIndex + 1)];
        // recordList.splice(recordIndex, 1);

        const newData = transformData(data, newRecords);

        store.writeQuery({
            query,
            data: newData,
            variables,
        });
    }
};

export const addRecordToCache = (store, query, variables, transformData) => {
    if (!transformData) throw new Error('Must implement transformData');

    const data = store.readQuery({
        query,
        variables,
    });

    const newData = transformData(data);

    store.writeQuery({
        query,
        data: newData,
        variables,
    });
};
